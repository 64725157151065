import { Table, Typography, Badge, Tag, Tooltip } from "antd";
import { toRupiah } from "libs";
import { Link } from "react-router-dom";
import TrainingsListItemActionsContainer from "../../../../containers/adminRole/training/TrainingsListItemActionsContainer";

const columns = ({ showLocationColumn, showTypeColumn }) => {
  let backgroundColor = "#faf70d";
  let result = [
    {
      title: "Tipe",
      dataIndex: "is_online",
      key: "is_online",
      render: (isOnline, field) => {
        return {
          props: {
            style: { background: !field.is_running ? backgroundColor : "" }
          },
          children: (
            <>
              {isOnline &&
                <Tag color="green" style={{ margin: 1 }}>ONLINE</Tag>
              }
              {((!isOnline && field.location) || field.is_offline) &&
                <Tag color="blue" style={{ margin: 1 }}>OFFLINE</Tag>
              }
            </>
          )
        }
      },
    },
    {
      title: "Nama",
      dataIndex: "training_option",
      key: "training_option",
      render: (training_option, field) => {
        return {
          props: {
            style: { background: !field.is_running ? backgroundColor : "" }
          },
          children: (
            <Badge
              status={field.is_running ? "success" : "error"}
              text={
                <Link to={"/training/" + field.id}>{training_option.name}</Link>
              }
            />
          )
        }
      },
    },
    {
      title: "Lokasi",
      dataIndex: "location",
      key: "location",
      render: (location, field) => {
        return {
          props: {
            style: { background: !field.is_running ? backgroundColor : "" }
          },
          children: (
            <div>{location}</div>
          )
        }
      },
    },
    {
      title: "Jadwal",
      dataIndex: "dates_summary",
      key: "dates_summary",
      render: (datesSummary, field) => {
        return {
          props: {
            style: { background: !field.is_running ? backgroundColor : "" }
          },
          children: (
            (datesSummary ? datesSummary : "-")
          )
        }
      },
    },
    {
      title: "Harga",
      dataIndex: "price",
      key: "price",
      responsive: ["md"],
      render: (text, field) => {
        return {
          props: {
            style: { background: !field.is_running ? backgroundColor : "" }
          },
          children: (
            <>
              {(field.is_online) &&
                <>
                  <Tooltip title="Online">
                    <Tag color="green" style={{ margin: 1 }}>{toRupiah(text)}</Tag>
                  </Tooltip>
                </>
              }
              {(field.price_offline > 0 || (field.location && !field.is_online)) &&
                <>
                  <Tooltip title="Offline">
                    <Tag color="blue" style={{ margin: 1 }}>{toRupiah(field.price_offline > 0 ? field.price_offline : text)}</Tag>
                  </Tooltip>
                </>
              }
            </>
          )
        }
      }
      ,
    },
    {
      title: "Peserta",
      dataIndex: "total_participants",
      key: "total_participants",
      align: "center",
      responsive: ["md"],
      render: (total_participants, field) => {
        return {
          props: {
            style: { background: !field.is_running ? backgroundColor : "" }
          },
          children: (
            (
              <div>
                <Typography.Text strong>{total_participants}</Typography.Text>/
                {field.max_participants}
              </div>
            )
          )
        }
      }
      ,
    },
    {
      title: "Peserta belum lunas",
      dataIndex: "total_unpaid_off_participants",
      key: "total_unpaid_off_participants",
      align: "center",
      responsive: ["md"],
      render: (total_unpaid_off_participants, field) => {
        return {
          props: {
            style: { background: !field.is_running ? backgroundColor : "" }
          },
          children: (
            (
              <Typography.Text
                strong
                type={total_unpaid_off_participants > 0 ? "danger" : "secondary"}
              >
                {total_unpaid_off_participants}
              </Typography.Text>
            )
          )
        }
      },
    },
    {
      title: "Trainer",
      dataIndex: "trainer",
      key: "trainer",
      responsive: ["md"],
      render: (trainer, field) => {
        return {
          props: {
            style: { background: !field.is_running ? backgroundColor : "" }
          },
          children: (
            (trainer ? trainer.name.split(" ")[0] : "-")
          )
        }
      },
    },
  ];
  result.push({
    key: "action",
    align: "right",
    responsive: ["md"],
    render: (field) => {
      return {
        props: {
          style: { background: !field.is_running ? backgroundColor : "" }
        },
        children: (
          (
            <TrainingsListItemActionsContainer
              downloadAttendance={field.dates_summary && field.total_participants > 0}
              downloadCertificates={field.total_participants > 0}
              cancelable={field.dates_summary}
              trainingId={field.id}
              isRunning={field.is_running}
            />
          )
        )
      }
    },
  });
  return result;
};

export default function TrainingsList({
  trainings = [],
  loading = false,
  showLocationColumn = true,
  showTypeColumn = true,
  totalTrainings = 0,
  onChangePagination,
  pageSize = 10,
  currentPage = 1,
}) {
  return (
    <Table
      loading={loading}
      size="small"
      columns={columns({
        showLocationColumn,
        showTypeColumn,
      })}
      dataSource={trainings.map((training) => ({
        ...training,
        key: training.id,
      }))}
      pagination={{
        total: totalTrainings,
        position: ["bottomCenter"],
        responsive: true,
        onChange: onChangePagination,
        pageSize,
        current: currentPage,
      }}
    />
  );
}
