// Import package
import React from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import { useLocation, useHistory } from "react-router-dom";
import { Modal } from "antd";
import {
  setErrorMessage,
  setLoadingMessage,
  setSuccessMessage,
} from "../../../../appRedux/actions/Common";
import { createTask } from "../../../../appRedux/actions/Task";

// Import file
import TrainingRegistrationsListActions from "../../../../components/adminRole/trainingRegistration/TrainingRegistrationsListActions";
import {
  deleteTrainingRegistration,
  getTrainingRegistrations,
  updateTrainingRegistration,
  sendDpPaymentInformtionEmailAndWa,
  sendPaidOffPaymentInformtionEmailAndWa
} from "../../../../appRedux/actions/TrainingRegistration";

export default function TrainingRegistrationsListActionsContainer({
  trainingRegistration,
}) {
  const messageKey = "TrainingRegistrationsListActionsContainer";
  const { id, status } = trainingRegistration;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let filter = queryString.parse(useLocation().search);
  const handleDelete = () => {
    Modal.confirm({
      title: "Konfirmasi",
      content: "Apakah anda yakin ingin menghapus registrasi ini ?",
      onOk: () =>
        dispatch(deleteTrainingRegistration(id)).then(() =>
          dispatch(getTrainingRegistrations({ ...filter, current_page: 1 }))
        ),
    });
  };
  const handlePaidDownPayment = () => {
    dispatch(
      updateTrainingRegistration({
        ...trainingRegistration,
        already_paid: 500000,
        dp_deadline: null
      })
    ).then((data) => dispatch(sendDpPaymentInformtionEmailAndWa({ id: data.id })))
  };
  const handlePaidOff = () => {
    dispatch(
      updateTrainingRegistration({
        ...trainingRegistration,
        already_paid: trainingRegistration.total_price,
        dp_deadline: null
      })
    ).then((data) => dispatch(sendPaidOffPaymentInformtionEmailAndWa({ id: data.id })))
  };
  const handleCancel = () => {
    dispatch(
      updateTrainingRegistration({
        ...trainingRegistration,
        is_canceled: true,
      })
    );
  };
  const handleEdit = () => {
    history.push(
      "/training-registration/" + id + "/edit?back_link=" + location.pathname
    );
  };
  const handleInvoice = () => {
    dispatch(setLoadingMessage("Membuat invoice...", messageKey));
    dispatch(
      createTask({
        task: "generate_training_invoice",
        args: { training_registration_id: trainingRegistration.id },
      })
    )
    .then((result) => {
      dispatch(setSuccessMessage("Membuat invoice berhasil", messageKey));
      window.open(result.url, "_blank")
    })
    .catch((e) => {
      dispatch(setErrorMessage(e, messageKey));
    });
  }
  return (
    <TrainingRegistrationsListActions
      onDelete={handleDelete}
      showSetPaidDownPayment={status === "waiting_for_down_payment"}
      showSetPaidOff={
        status === "waiting_for_paid_off" ||
        status === "waiting_for_down_payment"
      }
      showCancel={status !== "canceled"}
      showInvoice={status == "paid_off"}
      onSetPaidDownpayment={handlePaidDownPayment}
      onSetPaidOff={handlePaidOff}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onInvoice={handleInvoice}
    />
  );
}
