import { setErrorMessage } from "./Common";
import { baseRequest } from "../../util/Api";
import {
  SET_DASHBOARD_SUMMARY,
  SET_DASHBOARD_SUMMARY_LOADING,

  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH,
  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH,
  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH,
  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_FILTER,

  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH,
  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING,
  SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_FILTER,

} from "../../constants/ActionTypes";

export const getDashboardSummary = () => (dispatch) => {
  dispatch({
    type: SET_DASHBOARD_SUMMARY_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/summary")
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_SUMMARY,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_SUMMARY_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingParticipantRegularGraph = (v) => (dispatch) => {
  let newFilter = {};
  if (v === "1") {
    newFilter = { is_online: null, location: null, is_retraining: false };
  } else if (v === "2") {
    newFilter = { is_online: true, location: null, is_retraining: false };
  } else if (v === "3") {
    newFilter = { is_online: false, is_retraining: false };
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-participant-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_FILTER,
        payload: v,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_REGULAR_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingParticipantRetrainingGraph = (v) => (dispatch) => {
  let newFilter = {};
  if (v === "1") {
    newFilter = { is_online: null, location: null, is_retraining: true };
  } else if (v === "2") {
    newFilter = { is_online: true, location: null, is_retraining: true };
  } else if (v === "3") {
    newFilter = { is_online: false, is_retraining: true };
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-participant-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_FILTER,
        payload: v,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_PARTICIPANT_RETRAINING_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingRegistPaidGraph = (v) => (dispatch) => {
  let newFilter = {};
  if (v === "1") {
    newFilter = { is_online: null, location: null, is_cancel: false };
  } else if (v === "2") {
    newFilter = { is_online: true, location: null, is_cancel: false };
  } else if (v === "3") {
    newFilter = { is_online: false, is_cancel: false };
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-register-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_FILTER,
        payload: v,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_PAID_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingRegistCancelGraph = (v) => (dispatch) => {
  let newFilter = {};
  if (v === "1") {
    newFilter = { is_online: null, location: null, is_cancel: true };
  } else if (v === "2") {
    newFilter = { is_online: true, location: null, is_cancel: true };
  } else if (v === "3") {
    newFilter = { is_online: false, is_cancel: true };
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-register-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_FILTER,
        payload: v,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_REGIST_CANCEL_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingStatusRunningGraph = (v) => (dispatch) => {
  let newFilter = {};
  if (v === "1") {
    newFilter = { is_online: null, location: null, is_running: true };
  } else if (v === "2") {
    newFilter = { is_online: true, location: null, is_running: true };
  } else if (v === "3") {
    newFilter = { is_online: false, is_running: true };
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-status-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_FILTER,
        payload: v,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_RUNNING_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingStatusCancelGraph = (v) => (dispatch) => {
  let newFilter = {};
  if (v === "1") {
    newFilter = { is_online: null, location: null, is_running: false };
  } else if (v === "2") {
    newFilter = { is_online: true, location: null, is_running: false };
  } else if (v === "3") {
    newFilter = { is_online: false, is_running: false };
  }
  dispatch({
    type: SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING,
    payload: true,
  });
  baseRequest("get", "/dashboard/training-status-graph", newFilter)
    .then((data) => {
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH,
        payload: data,
      });
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_FILTER,
        payload: v,
      });
    })
    .catch((e) => {
      dispatch(setErrorMessage(e));
      dispatch({
        type: SET_DASHBOARD_TRAINING_STATUS_CANCEL_GRAPH_LOADING,
        payload: false,
      });
    });
};

export const getDashboardTrainingRegistMonthlyGraph =
  (filter) => (dispatch) => {
    dispatch({
      type: SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING,
      payload: true,
    });
    baseRequest("get", "/dashboard/training-registration-monthly-graph", filter)
      .then((data) => {
        dispatch({
          type: SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH,
          payload: data,
        });
        dispatch({
          type: SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_FILTER,
          payload: filter,
        });
      })
      .catch((e) => {
        dispatch(setErrorMessage(e));
        dispatch({
          type: SET_DASHBOARD_TRAINING_REGIST_MONTHLY_GRAPH_LOADING,
          payload: false,
        });
      });
  };

export const getDashboardTrainingParticipantsMonthlyGraph =
  (filter) => (dispatch) => {
    dispatch({
      type: SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING,
      payload: true,
    });
    baseRequest("get", "/dashboard/training-participants-monthly-graph", filter)
      .then((data) => {
        dispatch({
          type: SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH,
          payload: data,
        });
        dispatch({
          type: SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_FILTER,
          payload: filter,
        });
      })
      .catch((e) => {
        dispatch(setErrorMessage(e));
        dispatch({
          type: SET_DASHBOARD_TRAINING_PARTICIPANTS_MONTHLY_GRAPH_LOADING,
          payload: false,
        });
      });
  };

export const getDashboardTrainingRegistDailyGraph = (filter) => {
  return new Promise((resolve, reject) => {
    baseRequest("get", "/dashboard/training-registration-daily-graph", filter)
      .then((data) => {
        resolve(data)
      }).catch((e) => {
        reject(e)
      })
  })
}