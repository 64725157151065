import React, { useEffect } from "react";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardTrainingStatusRunningGraph } from "../../../appRedux/actions";
import FilterBarChartParticipant from "../../../components/adminRole/dashboard/FilterBarChartParticipant";

export default function BarChartTrainingRunning() {

  const dispatch = useDispatch();
  const { trainingStatusRunningGraph, trainingStatusRunningGraphFilter } =
    useSelector(({ dashboard }) => dashboard);

  useEffect(() => {
    dispatch(
      getDashboardTrainingStatusRunningGraph(trainingStatusRunningGraphFilter)
    );
  }, []);

  const handleChangeType = (v) => {
    dispatch(getDashboardTrainingStatusRunningGraph(v));
  };

  const getTrainingStatusRunData = () =>
  trainingStatusRunningGraph.map(function (training) {
      return {
        name: training.training_option_name,
        training_berjalan: training.total_training_running,
      };
    });

  return (
    <>
      <FilterBarChartParticipant
        value={trainingStatusRunningGraphFilter}
        onChange={handleChangeType}
      />
      <DashboardBarChart
        barKey="training_berjalan"
        fillColor="#6db7ff"
        data={getTrainingStatusRunData()}
        barName="Training Berjalan"
      />
    </>
  );
}
