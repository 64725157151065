// Import Package
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Import File
import {
  createTrainer,
  createUser,
  updateUser,
  updateTrainer,
} from "../../../../appRedux/actions";
import TrainerForm from "../../../../components/adminRole/trainer/TrainerForm";

// Component
export default function TrainerFormContainer({ trainer }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (data) => {
    if (!trainer) {
      dispatch(
        createUser({
          name: data.name,
          email: data.email,
          password: data.password,
          role: "trainer",
          is_verified: true
        })
      )
        .then((newData) => {
          dispatch(
            createTrainer({
              ...data,
              user_id: newData.id,
            })
          );
        })
        .then(() => history.push("/trainer"));
    } else {
      dispatch(updateTrainer({ ...data, id: trainer.id }))
        .then((newData) => {
          dispatch(
            updateUser({
              id: newData.user_id,
              email: newData.email,
              role: "trainer",
            })
          );
        })
        .then(() => history.push("/trainer"));
    }
  };

  return <TrainerForm onSubmit={handleSubmit} trainer={trainer} />;
}
