// Import package
import React, { useEffect } from "react";
import { Row, Col, Select } from "antd";
import { ResponsiveContainer, Tooltip } from "recharts";
import { Area, AreaChart } from "recharts";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment-timezone';

// Import file
import PageContentWrapper from "../../components/PageContentWrapper";
import ChartCard from "../../components/dashboard/Listing/ChartCard";
import {
  getTrainingOptions,
  getDashboardSummary,
} from "../../appRedux/actions";
import BarChartRegularParticipantsThisMonth from "../../containers/adminRole/dashboard/BarChartRegularParticipantsThisMonth";
import BarChartRetrainingParticipantsThisMonth from "../../containers/adminRole/dashboard/BarChartRetrainingParticipantsThisMonth";
import BarChartRegisterPaidThisMonth from "../../containers/adminRole/dashboard/BarChartRegisterPaidThisMonth";
import BarChartRegisterCancelThisMonth from "../../containers/adminRole/dashboard/BarChartRegisterCancelThisMonth";
import BarChartMonthlyParticipant from "../../containers/adminRole/dashboard/BarChartMonthlyParticipant";
import BarChartMonthlyRegister from "../../containers/adminRole/dashboard/BarChartMonthlyRegister";
import BarChartTrainingRunning from "../../containers/adminRole/dashboard/BarChartTrainingRunning";
import BarChartTrainingCancel from "../../containers/adminRole/dashboard/BarChartTrainingCancel";
import BarChartDailyRegister from "../../containers/adminRole/dashboard/BarChartDailyRegister";

export const revenueData = [
  { name: "Page A", revenue: 850 },
  { name: "Page B", revenue: 300 },
  { name: "Page C", revenue: 1100 },
  { name: "Page D", revenue: 600 },
];

export const propertiesData = [
  { name: "Page A", properties: 200 },
  { name: "Page B", properties: 1200 },
  { name: "Page C", properties: 600 },
  { name: "Page D", properties: 1600 },
  { name: "Page D", properties: 1000 },
  { name: "Page H", properties: 2260 },
  { name: "Page K", properties: 800 },
];
export const citiesData = [
  { name: "Page A", cities: 200 },
  { name: "Page B", cities: 1200 },
  { name: "Page C", cities: 600 },
  { name: "Page D", cities: 1600 },
  { name: "Page D", cities: 1000 },
  { name: "Page H", cities: 2260 },
  { name: "Page K", cities: 800 },
];
export const visitsData = [
  { name: "Page A", visit: 200 },
  { name: "Page B", visit: 1200 },
  { name: "Page C", visit: 600 },
  { name: "Page D", visit: 1600 },
  { name: "Page D", visit: 1000 },
  { name: "Page H", visit: 2260 },
  { name: "Page K", visit: 800 },
];

export const queriesData = [
  { name: "Page A", queries: 200 },
  { name: "Page B", queries: 1200 },
  { name: "Page C", queries: 600 },
  { name: "Page D", queries: 1600 },
  { name: "Page D", queries: 1000 },
  { name: "Page H", queries: 2260 },
  { name: "Page K", queries: 800 },
];

export default function AdminRolePage() {
  const dispatch = useDispatch();
  const { summary } = useSelector(({ dashboard }) => dashboard);
  const { trainingOptions } = useSelector(
    ({ trainingOption }) => trainingOption
  );

  useEffect(() => {
    dispatch(getTrainingOptions({ page_size: 100 }));
    dispatch(getDashboardSummary());
  }, []);

  // const handleChangeMonth = (v) => {

  // }

  return (
    <PageContentWrapper 
      title="Dashboard"
      // extra={
      //   (<Select
      //     defaultValue={moment().startOf('month').toISOString()}
      //     dropdownMatchSelectWidth={false}
      //     onChange={handleChangeMonth}
      //   >
      //     <Select.Option
      //       value={moment().add(-1, 'months').startOf('month').toISOString()}
      //     >
      //       {moment().add(-1, 'months').format('MMMM YYYY')}
      //     </Select.Option>
      //     <Select.Option value={moment().startOf('month').toISOString()}>
      //       {moment().format('MMMM YYYY')}
      //     </Select.Option>
      //   </Select>)
      // }
      >
      <Row>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <ChartCard
            chartProperties={{
              title: "KELAS BULAN INI",
              prize: summary.total_trainings_this_month,
              icon: "stats",
              bgColor: "primary",
              styleName: "up",
              desc: "Total",
            }}
            children={
              <ResponsiveContainer width="100%" height={75}>
                <AreaChart
                  data={propertiesData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <Tooltip />
                  <Area
                    dataKey="properties"
                    strokeWidth={0}
                    stackId="2"
                    stroke="#092453"
                    fill="#092453"
                    fillOpacity={1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            }
          />
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <ChartCard
            chartProperties={{
              title: "TRAINING",
              prize: summary.total_trainings_run_this_month,
              desc: "Jalan",
              prize2: summary.total_trainings_cancel_this_month,
              desc2: "Cancel",
              icon: "stats",
              bgColor: "orange",
              styleName: "up",
            }}
            children={
              <ResponsiveContainer width="100%" height={75}>
                <AreaChart
                  data={citiesData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <Tooltip />
                  <Area
                    dataKey="cities"
                    type="monotone"
                    strokeWidth={0}
                    stackId="2"
                    stroke="#C87000"
                    fill="#C87000"
                    fillOpacity={1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            }
          />
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <ChartCard
            chartProperties={{
              title: "PENDAFTAR",
              prize: summary.total_trainings_register_paid_this_month,
              desc: "Bayar",
              prize2: summary.total_trainings_register_cancel_this_month,
              desc2: "Cancel",
              icon: "stats",
              bgColor: "teal",
              styleName: "down",
            }}
            children={
              <ResponsiveContainer width="100%" height={75}>
                <AreaChart
                  data={visitsData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <Tooltip />
                  <Area
                    dataKey="visit"
                    strokeWidth={0}
                    stackId="2"
                    stroke="#158765"
                    fill="#158765"
                    fillOpacity={1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            }
          />
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={24}>
          <ChartCard
            chartProperties={{
              title: "PESERTA",
              prize: summary.total_training_participants_regular_this_month,
              desc: "Regular",
              prize2: summary.total_training_participants_retraining_this_month,
              desc2: "Ngulang",
              icon: "stats",
              bgColor: "blue",
              styleName: "down",
            }}
            children={
              <ResponsiveContainer width="100%" height={75}>
                <AreaChart
                  data={queriesData}
                  margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                >
                  <Tooltip />
                  <Area
                    dataKey="queries"
                    strokeWidth={0}
                    stackId="2"
                    stroke="#BB1258"
                    fill="#07599d"
                    fillOpacity={1}
                  />
                </AreaChart>
              </ResponsiveContainer>
            }
          />
        </Col>
      </Row>

      {/* (GRAFIK BATANG) Peserta regular bulan ini */}
      <BarChartRegularParticipantsThisMonth />
      <br />

      {/* (GRAFIK BATANG) Peserta mengulang bulan ini */}
      <BarChartRetrainingParticipantsThisMonth />
      <br />

      {/* (GRAFIK BATANG) Pendaftar bayar bulan ini */}
      <BarChartRegisterPaidThisMonth />
      <br />

      {/* (GRAFIK BATANG) Pendaftar cancel bulan ini */}
      <BarChartRegisterCancelThisMonth />
      <br />

      {/* (GRAFIK BATANG) Peserta bulanan */}
      <BarChartMonthlyParticipant trainingOptions={trainingOptions} />
      <br />

      {/* (GRAFIK BATANG) Pendaftar bulanan */}
      <BarChartMonthlyRegister trainingOptions={trainingOptions} />
      <br />

      {/* (GRAFIK BATANG) Pendaftar harian */}
      <BarChartDailyRegister trainingOptions={trainingOptions} />
      <br />

      {/* (GRAFIK BATANG) Training yang berjalan bulan ini */}
      <BarChartTrainingRunning />
      <br />

      {/* (GRAFIK BATANG) Training cancel bulan ini */}
      <BarChartTrainingCancel />
      <br />
    </PageContentWrapper>
  );
}
