// Import package
import React from "react";
import { Form, Input, Button } from "antd";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

// Component
export default function EditProfile({ onSubmit, user }) {
  return (
    <Form
      layout="vertical"
      style={{ padding: 10 }}
      onFinish={(values) => {
        onSubmit({ ...values });
      }}
      initialValues={{ ...user }}
    >
      <Form.Item
        name="name"
        label="Nama lengkap"
        rules={[
          {
            required: true,
            message: "Mohon masukkan nama lengkap peserta!",
          },
        ]}
      >
        <Input placeholder="Dimas Hermawan" />
      </Form.Item>
      <Form.Item
        name="email"
        label="Alamat email"
        rules={[
          {
            type: "email",
            required: true,
            message: "Mohon masukkan alamat email dengan benar",
          },
        ]}
      >
        <Input placeholder="email@gmail.com" />
      </Form.Item>
      <Form.Item name="whatsapp_number" label="Nomor whatsapp">
        <PhoneInput
          defaultCountry="ID"
          style={{ width: "100%" }}
          // onChange={(v) => form.setFieldsValue({ whatsapp_number: v })}
          placeholder="081362778829"
        />
      </Form.Item>
      <Form.Item name="telegram_username" label="Username Telegram">
        <Input placeholder="hola" />
      </Form.Item>
      <Form.Item name="linkedin_url" label="Alamat URL LinkedId">
        <Input placeholder="http://linkedin.com/" />
      </Form.Item>
      <Form.Item name="certifications" label="Sertifikasi">
        <Input placeholder="CCNA, MTCNA, AAD" />
      </Form.Item>
      <Form.Item>
        <center>
          <Button size="large" type="primary" htmlType="submit">
            Simpan
          </Button>
        </center>
      </Form.Item>
    </Form>
  );
}
