import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  TimePicker,
  Input,
  InputNumber,
  Radio,
  Switch,
  Button,
  DatePicker,
  Modal,
  Descriptions,
  List,
  Typography,
  AutoComplete,
} from "antd";
import { toRupiah } from "../../../../libs";
import { baseRequest } from "../../../../util/Api";
import _ from "lodash";
import moment from "moment-timezone";
import {
  UpOutlined,
  DownOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
export default function NewTrainingForm({
  trainers = [],
  trainersLoading = false,
  trainingOptions = [],
  trainingOptionsLoading = false,
  onSubmit,
  submitLoading = false,
  showDatesField = true,
  update = false,
  training,
}) {
  const [form] = Form.useForm();
  const [showAdvancedFields, setShowAdvancedFields] = useState(false);
  const [locations, setLocations] = useState([]);
  const [locationRequired, setLocationRequired] = useState(true);
  const [type, setType] = useState(3);

  useEffect(async () => {
    const data = await baseRequest("get", "/trainings/locations", null);
    let options = [];
    data.map((value) => {
      options.push({
        value: value,
      });
    });
    setLocations(options);

    if (training) {
      let typeValue = 3;
      if (training.is_online) {
        typeValue = 1;
        setLocationRequired(false);
      }
      if (training.is_offline || training.location) {
        typeValue = 2;
      }
      if (training.is_online && training.is_offline) {
        typeValue = 3;
      }
      if (typeValue > 1)
        setLocationRequired(true);
      setType(typeValue)
      form.setFieldsValue({ type: typeValue });
    }
  }, []);

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={
          !update
            ? {
              location: null,
              trainer_id: null,
              time_start: moment("09:00", "HH:mm"),
              time_end: moment("17:00", "HH:mm"),
              // is_online: true,
              publicly_visible: true,
              price: 2500000,
              price_offline: 2500000,
              max_participants: 10,
              max_participants_offline: 10,
              note: "",
              notification_first: true,
              notification_full: true,
              days_before_closing: 2,
            }
            : {
              ...training,
              time_start: moment(training.time_start, "HH:mm"),
              time_end: moment(training.time_end, "HH:mm"),
            }
        }
        preserve
        onFinish={(v) => {
          v.is_online = type % 2 == 1;
          v.is_offline = type > 1;
          if (!update) {
            v.dates = _.uniq(
              v.dates.map((date) => date.endOf("day").toISOString())
            );
          }
          const result = {
            default_price: v.price,
          };
          const trainingOption = trainingOptions.find(
            (o) => o.id === v.training_option_id
          );
          const trainer = trainers.find((o) => o.id === v.trainer_id);
          Modal.confirm({
            title: "Ringkasan",
            icon: null,
            style: { top: 10 },
            width: 700,
            content: (
              <Descriptions bordered size="small" column={1}>
                <Descriptions.Item label="Pilihan training">
                  {trainingOption.name}
                </Descriptions.Item>
                <Descriptions.Item label="Tipe">
                  {/* {v.is_online ? "Online" : "Offline"} */}
                  {(() => {
                    switch (type) {
                      case 1: return "Online";
                      case 2: return "Offline";
                      case 3: return "Online dan Offline";
                    }
                  })()}
                </Descriptions.Item>
                {!update && (
                  <Descriptions.Item label="Tanggal">
                    <List>
                      {v.dates.sort().map((date, index) => {
                        return (
                          <List.Item key={index}>
                            Hari ke-{index + 1} :{" "}
                            {moment(date).format("dddd, D MMMM yyyy")}
                          </List.Item>
                        );
                      })}
                    </List>
                  </Descriptions.Item>
                )}
                {type > 1 &&
                  <>
                    <Descriptions.Item label="Lokasi">
                      {v.location ? v.location : "-"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Ruang">
                      {v.room ? v.room : "-"}
                    </Descriptions.Item>
                  </>
                }
                <Descriptions.Item label="Trainer">
                  {trainer.name}
                </Descriptions.Item>
                <Descriptions.Item label={"Harga" + (type == 3 ? " Online" : "")}>
                  {trainingOption.default_price > v.price && (
                    <Typography.Text delete style={{ display: "block" }}>
                      {toRupiah(trainingOption.default_price)}
                    </Typography.Text>
                  )}
                  {toRupiah(result.default_price)}
                </Descriptions.Item>
                {type == 3 &&
                  <Descriptions.Item label={"Harga Offline"}>
                    {trainingOption.default_price > v.price_offline && (
                      <Typography.Text delete style={{ display: "block" }}>
                        {toRupiah(trainingOption.default_price)}
                      </Typography.Text>
                    )}
                    {toRupiah(v.price_offline)}
                  </Descriptions.Item>
                }
                <Descriptions.Item label={"Maksimal peserta" + (type == 3 ? " Online" : "")}>
                  {v.max_participants}
                </Descriptions.Item>
                {type == 3 &&
                  <Descriptions.Item label={"Maksimal peserta Offline"}>
                    {v.max_participants_offline}
                  </Descriptions.Item>
                }
                <Descriptions.Item label="Waktu">
                  {v.time_start.format("HH:mm")} - {v.time_end.format("HH:mm")}
                </Descriptions.Item>
                <Descriptions.Item label="Tampilkan di halaman jadwal untuk publik">
                  {v.publicly_visible ? "Iya" : "Tidak"}
                </Descriptions.Item>
                <Descriptions.Item label="Mengirim notifikasi jika ada pendaftar pertama">
                  {v.notification_first ? "Iya" : "Tidak"}
                </Descriptions.Item>
                <Descriptions.Item label="Mengirim notifikasi jika kelas penuh">
                  {v.notification_full ? "Iya" : "Tidak"}
                </Descriptions.Item>
                <Descriptions.Item label="Hari sebelum training ditutup">
                  {v.days_before_closing}
                </Descriptions.Item>
                <Descriptions.Item label="Catatan">
                  {v.note}
                </Descriptions.Item>
              </Descriptions>
            ),
            okText: "Simpan",
            onOk: () =>
              onSubmit({
                ...v,
                time_start: v.time_start.format("HH:mm"),
                time_end: v.time_end.format("HH:mm"),
              }),
          });
        }}
        onValuesChange={(v) => {
          const trainingOption = trainingOptions.find(
            (o) => o.id === form.getFieldValue('training_option_id')
          );
          if ("training_option_id" in v) {
            form.setFieldsValue({
              price: trainingOption.default_price,
              price_offline: trainingOption.default_price,
              max_participants: trainingOption.default_max_participant,
              max_participants_offline: trainingOption.default_max_participant_offline,
            });
          }
          if ("type" in v) {
            if (v.type == 2) {
              form.setFieldsValue({ max_participants: trainingOption.default_max_participant_offline })
            } else {
              form.setFieldsValue({ max_participants: trainingOption.default_max_participant })
            }
          }
        }}
      >
        <Form.Item
          name="training_option_id"
          label="Pilihan training"
          rules={[{ required: true, message: "Mohon pilih pilihan training" }]}
        >
          {!update || (update && !trainingOptionsLoading) ? (
            <Select
              placeholder="Pilih salah satu training"
              showSearch
              optionFilterProp="label"
              loading={trainingOptionsLoading}
            >
              {trainingOptions.map((t) => (
                <Select.Option key={t.id} value={t.id} label={t.name}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select disabled>
              <Select.Option
                key={training.training_option.id}
                value={training.training_option.id}
              >
                {training.training_option.name}
              </Select.Option>
            </Select>
          )}
        </Form.Item>

        {showDatesField && (
          <Form.List
            name="dates"
            rules={[
              {
                validator: async (_, dates) => {
                  if (!dates || dates.length < 1) {
                    return Promise.reject(
                      new Error("Mohon tambahkan tanggal pelaksanaan training")
                    );
                  }
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => {
              return (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      key={field.key}
                      label={index === 0 ? "Tanggal pelaksanaan" : ""}
                    >
                      <Form.Item
                        {...field}
                        noStyle
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            required: true,
                            message: "Mohon masukkan tanggal",
                          },
                        ]}
                      >
                        <DatePicker multiple format="dddd, D MMMM yyyy" />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          style={{ marginLeft: 10, cursor: "pointer" }}
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={() => add()}
                    >
                      Tambah tanggal pelaksanaan
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        )}
        <Form.Item name="type" label="Tipe">
          {/* <Radio.Group>
            <Radio.Button value={true}>Online</Radio.Button>
            <Radio.Button value={false}>Offline</Radio.Button>
          </Radio.Group> */}
          <Select
            value={type}
            defaultValue={type}
            onChange={(value) => {
              setType(value);
              if (value == 1)
                setLocationRequired(false);
              else
                setLocationRequired(true);
            }}
          >
            <Select.Option value={3} label="semua">
              Online dan Offline
            </Select.Option>
            <Select.Option value={1} label="online">
              Online
            </Select.Option>
            <Select.Option value={2} label="offline">
              Offline
            </Select.Option>
          </Select>
        </Form.Item>
        {locationRequired &&
          <>
            <Form.Item shouldUpdate noStyle>
              <Form.Item name="location" label="Lokasi">
                <AutoComplete
                  placeholder="Masukkan lokasi training"
                  options={locations}
                  filterOption={(inputValue, locations) =>
                    locations.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                  rules={[{ required: locationRequired, message: "Mohon pilih lokasi" }]}
                />
              </Form.Item>
              <Form.Item label="Ruang" name="room">
                <Input placeholder="Masukkan nama ruang training" />
              </Form.Item>
            </Form.Item>
          </>
        }

        <Form.Item
          name="trainer_id"
          label="Trainer"
          rules={[{ required: true, message: "Mohon pilih trainer" }]}
        >
          {!update || (update && !trainingOptionsLoading) ? (
            <Select
              showSearch
              optionFilterProp="label"
              placeholder="Pilih salah satu trainer"
              loading={trainersLoading}
            >
              {trainers.map((t) => (
                <Select.Option label={t.name} key={t.id} value={t.id}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
          ) : (
            <Select disabled>
              <Select.Option
                key={training.trainer.id}
                value={training.trainer.id}
              >
                {training.trainer.name}
              </Select.Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item
          name="price"
          label={"Harga" + (type == 3 ? " Online" : "")}
          rules={[
            {
              required: true,
              message: "Mohon masukkan harga",
            },
          ]}
        >
          <Input type="number" addonBefore="Rp" />
        </Form.Item>

        {(type == 3) &&
          <Form.Item
            name="price_offline"
            label="Harga Offline"
            rules={[
              {
                required: true,
                message: "Mohon masukkan harga",
              },
            ]}
          >
            <Input type="number" addonBefore="Rp" />
          </Form.Item>
        }

        <Form.Item hidden name="default_price" label="Harga">
          <Input type="number" addonBefore="Rp" />
        </Form.Item>

        <Form.Item
          name="max_participants"
          label={"Jumlah Maksimal Peserta" + (type == 3 ? " Online" : "")}
          rules={[
            {
              required: true,
              message: "Mohon masukkan jumlah maksimal peserta",
            },
          ]}
        >
          <InputNumber min={0} />
        </Form.Item>

        {(type == 3) &&
          <Form.Item
            name="max_participants_offline"
            label={"Jumlah Maksimal Peserta Offline"}
            rules={[
              {
                required: true,
                message: "Mohon masukkan jumlah maksimal peserta",
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
        }

        <div style={{ display: showAdvancedFields ? "block" : "none" }}>
          <Form.Item
            label="Waktu mulai"
            name="time_start"
            rules={[
              {
                required: true,
                message: "Mohon masukkan waktu selesai training",
              },
            ]}
          >
            <TimePicker format="HH:mm" />
          </Form.Item>
          <Form.Item
            label="Waktu selesai"
            name="time_end"
            rules={[
              {
                required: true,
                message: "Mohon masukkan waktu selesai training",
              },
            ]}
          >
            <TimePicker format="HH:mm" />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="publicly_visible"
            label="Tampilkan di halaman jadwal untuk publik"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="notification_first"
            label="Mengirim notifikasi jika ada pendaftar pertama"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name="notification_full"
            label="Mengirim notifikasi jika kelas penuh"
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name="days_before_closing"
            label="Hari sebelum training ditutup"
            rules={[
              {
                required: true,
                message: "Mohon masukkan jumlah hari",
              },
            ]}
          >
            <InputNumber min={0} />
          </Form.Item>
        </div>

        <Form.Item>
          <a
            href={() => false}
            onClick={() => setShowAdvancedFields(!showAdvancedFields)}
          >
            {showAdvancedFields ? <UpOutlined /> : <DownOutlined />} Tampilkan
            lebih {showAdvancedFields ? "sedikit" : "banyak"}
          </a>
        </Form.Item>
        <Form.Item name="note" label="Catatan">
          <Input.TextArea />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => (
            <center>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                loading={submitLoading}
                icon={<ArrowRightOutlined />}
              >
                Lanjutkan
              </Button>
            </center>
          )}
        </Form.Item>
      </Form>
    </>
  );
}
