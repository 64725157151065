import React, { useEffect } from "react";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardTrainingParticipantRegularGraph } from "../../../appRedux/actions";
import FilterBarChartParticipant from "../../../components/adminRole/dashboard/FilterBarChartParticipant";

export default function BarChartRegularParticipantsThisMonth() {
  const dispatch = useDispatch();
  const { trainingParticipantRegularGraph, trainingParticipantRegularGraphFilter } =
    useSelector(({ dashboard }) => dashboard);

  useEffect(() => {
    dispatch(
      getDashboardTrainingParticipantRegularGraph(
        trainingParticipantRegularGraphFilter,
        false
      )
    );
  }, []);

  const handleChangeType = (v) => {
    dispatch(getDashboardTrainingParticipantRegularGraph(v));
  };

  const getParticipantRegularData = () =>
  trainingParticipantRegularGraph.map(function (tpg) {
      return {
        name: tpg.training_option_name,
        peserta_regular: tpg.total_participants,
      };
    });

  return (
    <>
      <FilterBarChartParticipant
        value={trainingParticipantRegularGraphFilter}
        onChange={handleChangeType}
      />
      <DashboardBarChart
        barKey="peserta_regular"
        fillColor="#ed7e89"
        data={getParticipantRegularData()}
        barName="Peserta Regular"
      />
    </>
  );
}
