import React, { useEffect } from "react";
import DashboardBarChart from "../../../components/adminRole/dashboard/DashboardBarChart";
import { useSelector, useDispatch } from "react-redux";
import { getDashboardTrainingStatusCancelGraph } from "../../../appRedux/actions";
import FilterBarChartParticipant from "../../../components/adminRole/dashboard/FilterBarChartParticipant";

export default function BarChartTrainingCancel() {

  const dispatch = useDispatch();
  const { trainingStatusCancelGraph, trainingStatusCancelGraphFilter } =
    useSelector(({ dashboard }) => dashboard);

  useEffect(() => {
    dispatch(
      getDashboardTrainingStatusCancelGraph(trainingStatusCancelGraphFilter)
    );
  }, []);

  const handleChangeType = (v) => {
    dispatch(getDashboardTrainingStatusCancelGraph(v));
  };

  const getTrainingStatusRunData = () =>
  trainingStatusCancelGraph.map(function (training) {
      return {
        name: training.training_option_name,
        training_cancel: training.total_training_running,
      };
    });

  return (
    <>
      <FilterBarChartParticipant
        value={trainingStatusCancelGraphFilter}
        onChange={handleChangeType}
      />
      <DashboardBarChart
        barKey="training_cancel"
        fillColor="#ed7e89"
        data={getTrainingStatusRunData()}
        barName="Training Cancel"
      />
    </>
  );
}
