import React from 'react'
import { Space, Select, Row, Col } from "antd";

export default function FilterBarChartMonthlyRegister({
    valueFilterTraining,
    onChangeFilterTraining,
    trainingOptions,
    valueFilterStatus,
    onChangeFilterStatus
}) {
  return (
    <Row wrap gutter={[16, 16]} justify="space-between">
        <Col>
          <Space wrap>
            <Select
              showSearch
              value={valueFilterTraining}
              placeholder="Semua training"
              dropdownMatchSelectWidth={false}
              style={{ width: 200 }}
              onChange={onChangeFilterTraining}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value={null}>Semua training</Select.Option>
              {trainingOptions.map((t) => (
                <Select.Option key={t.id} value={t.id} label={t.name}>
                  {t.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              showSearch
              value={valueFilterStatus}
              placeholder="Semua pendaftar"
              dropdownMatchSelectWidth={false}
              style={{ width: 200 }}
              onChange={onChangeFilterStatus}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Select.Option value={null}>Semua pendaftar</Select.Option>
              <Select.Option value="canceled">Pendaftar cancel</Select.Option>
              <Select.Option value="paid">Pendaftar bayar</Select.Option>
            </Select>
          </Space>
        </Col>
      </Row>
  )
}
