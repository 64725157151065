// Import Package
import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

// Import File
import {
  createParticipant,
  updateParticipant,
  createUser,
  updateUser,
} from "../../../../appRedux/actions";
import ParticipantForm from "../../../../components/adminRole/participant/ParticipantForm";

// Component
export default function ParticipantFormContainer({ participant }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSubmit = (data) => {
    if (!participant) {
      dispatch(
        createUser({
          name: data.name,
          email: data.email,
          password: data.password,
          role: "participant",
          is_verified: true
        })
      )
        .then((newData) => {
          dispatch(
            createParticipant({
              ...data,
              user_id: newData.id,
            })
          );
        })
        .then(() => history.push("/participant"));
    } else {
      dispatch(updateParticipant({ ...data, id: participant.id }))
        .then((newData) => {
          dispatch(
            updateUser({
              id: newData.user_id,
              email: newData.email,
              role: "participant",
            })
          );
        })
        .then(() => history.push("/participant"));
    }
  };
  return <ParticipantForm onSubmit={handleSubmit} participant={participant} />;
}
