import React from 'react'
import { Space, Select, Row, Col } from "antd";

export default function FilterBarChartParticipant({ value, onChange }) {
  return (
    <Row wrap gutter={[16, 16]} justify="space-between">
        <Col></Col>
        <Col><Space wrap>
          <Select
            defaultValue={value}
            dropdownMatchSelectWidth={true}
            onChange={onChange}
            style={{ borderRadius: 0, width: 200 }}
          >
            <Select.Option value="1">Semua peserta</Select.Option>
            <Select.Option value="2">Peserta online</Select.Option>
            <Select.Option value="3">Peserta offline</Select.Option>
          </Select>
        </Space></Col>
      </Row>
  )
}
