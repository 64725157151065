import React from "react";
import { Descriptions, Typography, Tag, Badge } from "antd";
import { toRupiah } from "../../../../libs";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import CircularProgress from "../../../CircularProgress";
export default function TrainingDescription({ training, loading = false }) {
  return !loading ? (
    <Descriptions bordered size="middle" column={{ xs: 1, md: 2 }}>
      <Descriptions.Item label="Kode">#{training.id}</Descriptions.Item>
      <Descriptions.Item label="Pilihan training">
        <Link to={"/training-option/" + training.training_option_id}>
          {training.training_option.name}
        </Link>
      </Descriptions.Item>
      <Descriptions.Item label="Tipe">
        {training.is_online &&
          <Tag color="green" style={{ margin: 0, marginRight: "2px" }}>ONLINE</Tag>
        }
        {((!training.is_online && training.location) || training.is_offline) &&
          <Tag color="blue" style={{ margin: 0 }}>OFFLINE</Tag>
        }
      </Descriptions.Item>
      <Descriptions.Item label="Trainer">
        {training.trainer ? (
          <Link to={"/trainer/" + training.trainer_id}>
            {training.trainer.name}
          </Link>
        ) : (
          "-"
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        <Badge
          status={training.is_running ? "success" : "error"}
          text={training.is_running ? "Berjalan" : "Batal"}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Status pendaftaran">
        <Badge
          status={training.available_to_register ? "success" : "error"}
          text={training.available_to_register ? "Terbuka" : "Ditutup"}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Tanggal">
        {training.dates_summary ? training.dates_summary : "-"}
      </Descriptions.Item>
      <Descriptions.Item label="Penutupan Pendaftaran">
        {training.registration_deadline
          ? moment(training.registration_deadline).format("LLLL z")
          : "-"}
      </Descriptions.Item>
      {(training.is_offline || !training.is_online) && (
        <>
          <Descriptions.Item label="Lokasi">
            {training.location ? training.location : "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Ruang">
            {training.room ? training.room : "-"}
          </Descriptions.Item>
        </>
      )}

      <Descriptions.Item label={"Harga" + ((training.is_online && training.is_offline) ? " Online" : "")}>
        {training.training_option.default_price > training.price && (
          <Typography.Text delete style={{ display: "block" }}>
            {toRupiah(training.training_option.default_price)}
          </Typography.Text>
        )}
        {toRupiah(training.price)}
      </Descriptions.Item>
      {(training.is_online && training.is_offline) &&
        <Descriptions.Item label="Harga Offline">
          {training.training_option.default_price > training.price_offline && (
            <Typography.Text delete style={{ display: "block" }}>
              {toRupiah(training.training_option.default_price)}
            </Typography.Text>
          )}
          {toRupiah(training.price_offline)}
        </Descriptions.Item>
      }
      <Descriptions.Item label="Jumlah peserta">
        {training.total_participants} / {training.max_participants}
      </Descriptions.Item>
      <Descriptions.Item label="Waktu">
        {training.time_start} - {training.time_end}
      </Descriptions.Item>
      <Descriptions.Item label="Tampil di halaman jadwal untuk publik">
        {training.publicly_visible ? <CheckOutlined /> : <CloseOutlined />}
      </Descriptions.Item>
      <Descriptions.Item label="Mengirim notifikasi jika ada pendaftar pertama">
        {training.notification_first ? <CheckOutlined /> : <CloseOutlined />}
      </Descriptions.Item>
      <Descriptions.Item label="Mengirim notifikasi jika kelas penuh">
        {training.notification_full ? <CheckOutlined /> : <CloseOutlined />}
      </Descriptions.Item>
      <Descriptions.Item label="Hari sebelum training ditutup">
        {training.days_before_closing}
      </Descriptions.Item>
      <Descriptions.Item label="Jumlah peserta belum lunas">
        {training.total_unpaid_off_participants}
      </Descriptions.Item>
      <Descriptions.Item label="Jumlah peserta mengulang">
        {training.participants.filter((p) => p.is_retraining).length}
      </Descriptions.Item>
      <Descriptions.Item label="Catatan">
        {training.note}
      </Descriptions.Item>
    </Descriptions>
  ) : (
    <CircularProgress />
  );
}
